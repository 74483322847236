<template>
  <div>
    <a-modal
      v-model="visible"
      :title="action === 'Create' ? 'Tambah ' : 'Edit ' + ' User Role'"
      :dialogStyle="{ top: '10px' }"
      :destroyOnClose="true"
      :forceRender="true"
    >
      <template slot="footer">
        <a-button class="btn btn-outline-danger" @click="handleCancel">
          Cancel
        </a-button>
        <a-button
          class="btn btn-outline-primary"
          :loading="loading"
          @click="submit()"
        >
          Simpan
        </a-button>
      </template>
      <div class="row">
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">User</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-select
            class=""
            style="width: 100%;"
            show-search
            placeholder="Pilih user"
            option-filter-prop="children"
            v-model="input.user_id"
            :filter-option="filterOption"
          >
            <a-select-option v-for="(data, index) in datausers" :value="data.id" :key="index">
              {{ data.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Role</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-select
            class=""
            style="width: 100%;"
            show-search
            placeholder="Pilih Role"
            option-filter-prop="children"
            v-model="input.role_id"
            :filter-option="filterOption"
          >
            <a-select-option v-for="(data, index) in dataroles" :value="data.id" :key="index">
              {{ data.name }}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'

export default {
  data() {
    return {
      action: 'Create',
      editdata: [],
      input: {
        user_id: '',
        role_id: '',
      },
      editshiftmode: false,
      columns: [
        {
          title: 'Shift',
          dataIndex: 'shift',
          scopedSlots: {
            customRender: 'shift',
          },
        },
        {
          title: 'Hari',
          dataIndex: 'hari',
          scopedSlots: {
            customRender: 'hari',
          },
        },
        {
          title: 'Datang',
          dataIndex: 'datang',
          scopedSlots: {
            customRender: 'datang',
          },
        },
        {
          title: 'Pulang',
          dataIndex: 'pulang',
          scopedSlots: {
            customRender: 'pulang',
          },
        },
        {
          title: 'Opsi',
          dataIndex: '#',
          scopedSlots: {
            customRender: 'action',
          },
          align: 'center',
        },
      ],
      datatable: [],
      dataSource: [],
      datausers: [],
      dataroles: [],
      visible: false,
      loading: false,
      datenow: '',
    }
  },
  created() {
    this.datenow = lou.datenow()
  },
  methods: {
    moment,
    async submit() {
      this.loading = true
      var fd = [this.input]
      if (this.action === 'Create') {
        await lou.createMaster('otorisasi/userrole', fd, true, true)
      } else {
        fd[0].id = this.editdata.id
        fd[0].bpr_id = this.editdata.bpr_id
        await lou.updateMaster('otorisasi/userrole', fd, true, true)
      }
      this.loading = false
      this.$parent.getData()
      this.handleCancel()
    },
    async showModal(action, data) {
      var res = await lou.customUrlGet('users', false, true)
      var res1 = await lou.customUrlGet('otorisasi/roles', false, true)
      if (res) {
        this.datausers = res.data
      }
      if (res1) {
        this.dataroles = res1.data
      }
      this.editdata = this.$g.clone(data)
      if (action !== 'Create') {
        // console.log("moment().add(4, 'hours').format('HH:mm')", moment().add(4, 'hours').format('HH:mm'))
        this.input = {
          user_id: this.editdata.user_id,
          role_id: this.editdata.role_id,
        }
        this.editshiftmode = false
        this.datatable = this.editdata.jam_kerja
      } else {
        this.resetForm()
      }
      this.visible = true
      this.action = action
    },
    addshift() {
      if (
        this.input.shift === null ||
        this.input.hari === null ||
        this.input.datang === null ||
        this.input.pulang === null
      ) {
        lou.shownotif('Input shift, hari, datang, atau pulang masih kosong')
      } else {
        var data = {
          shift: this.input.shift,
          hari: this.input.hari,
          datang: this.input.datang.format('HH:mm'),
          pulang: this.input.pulang.format('HH:mm'),
        }
        if (this.editshiftmode) {
          this.datatable[this.input.editindex] = data
          this.editshiftmode = false
          this.input.editindex = null
        } else {
          this.datatable.push(data)
        }
        this.input.shift = ''
        this.input.hari = 'senin'
      }
    },
    editshift(index, record) {
      this.input.shift = record.shift
      this.input.hari = record.hari
      this.input.datang = moment(record.datang, 'HH:mm')
      this.input.pulang = moment(record.pulang, 'HH:mm')
      this.input.editindex = index
      this.editshiftmode = true
    },
    deleteshift(index) {
      // console.log('index', index)
      this.datatable.splice(index, 1)
    },
    handleCancel() {
      this.visible = false
      this.resetForm()
    },
    resetForm() {
      this.action = 'Create'
      this.editdata = []
      this.input = {
        user_id: '',
        role_id: '',
      }
      this.datatable = []
      this.editshiftmode = false
      this.visible = false
      this.loading = false
      this.datenow = ''
    },
    onSearch(searchText) {
      this.dataSource = !searchText ? [] : ['Pagi', 'Sore', 'Damn']
    },
    handleChange(value) {
      // console.log(`selected ${value}`)
    },
    handleBlur() {
      // console.log('blur')
    },
    handleFocus() {
      // console.log('focus')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    capt(word) {
      return lou.capitalize(word)
    },
  },
}
</script>

<style></style>
